import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../doc/img/logo/logo.png';
import logoDark from '../../doc/img/logo/logowhite.png';
import { useDispatch, useSelector } from "react-redux";
import { getMidiaUrlPropaganda } from '../../store/settings';
import { isMobile } from 'react-device-detect';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import * as RadioAction from '../../store/actionsgazeta/radio.actions'

const LogoArea = ({ className, dark }) => {

    const propagandas = useSelector(state => state.gazeta.propagandas)
    const dispatch = useDispatch();

    const getPrograma = () => {

        let data = new Date()
        let diaSemana = data.getDay()
        let hora = data.getHours()
        let minutos = data.getMinutes()

        let imagem = "00_insti"

        //semana de seg a sexta
        if (diaSemana > 0 && diaSemana < 6) {
            if (hora < 7 || (hora >= 20))
                imagem = "00_insti"
            else if (hora < 8)
                imagem = "00_central"
            else if ((hora <= 10) || (hora <= 10 && minutos < 31))
                imagem = "01_supermanha"
            else if (hora < 11)
                imagem = "01_supermanha"
            else if (hora < 12)
                imagem = "03_empauta"
            else if (hora < 13 && minutos < 31)
                imagem = "04_gazetanoticias"
            else if (hora < 13 && minutos < 46)
                imagem = "05_gazetaesporte1"
            else if (hora < 13)
                imagem = "06_variedades"
            else if (hora < 14)
                imagem = "07_ladoalado"
            else if (hora < 17)
                imagem = "08_noar"
            else if (hora < 18 && minutos < 31)
                imagem = "09_gazetaesporte2"
            else if (hora < 20)
                imagem = "10_gazetanativa"
        } else if (diaSemana === 6) {
            if ((hora < 7) || (hora >= 11 && minutos >= 15))
                imagem = "00_insti"
            else if (hora < 8 && minutos < 31)
                imagem = "11_topofertas"
            else if (hora < 8)
                imagem = "12_plantaogazeta"
            else if (hora < 10)
                imagem = "13_topbandas"
            else if (hora < 12 && minutos < 16)
                imagem = "14_tribunalivre"
        } else if (diaSemana === 0) {
            if (hora < 8 || hora >= 13)
                imagem = "00_insti"
            else if (hora < 8)
                imagem = "15_agrorural"
            else if (hora < 13)
                imagem = "16_tcheencontro"
        }

        if (isMobile) imagem = imagem + "_mobile"
        imagem = require("../../doc/img/programacao/" + imagem + ".jpg")


        if (propagandas.filter(propaganda => propaganda.funcao === "Header").length === 0) {
            return <NavLink
                to={"/"}
                onClick={async () => {
                    await dispatch(RadioAction.close())
                    dispatch(RadioAction.set("AM"))
                }}
            >
                <img src={imagem} alt="banner" />
            </NavLink>

        } else {
            return null
        }
    }

    return (
        <div className={`logo_area ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 align-self-center">
                        <div className="logo">
                            <Link to="/">
                                <img src={dark ? logoDark : logo} alt="logo" style={{ marginTop: 25 }} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-8 align-self-center">
                        <div className="banner1">
                            {
                                getPrograma()
                            }
                            {
                                propagandas.find(propaganda => propaganda.funcao === "Header") ?
                                    <a target="_blank" rel="noopener noreferrer" href={propagandas.find(propaganda => propaganda.funcao === "Header").link}>
                                        <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Header").chave)} alt="banner" />
                                    </a>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoArea;